.container-fluid {
    background: linear-gradient(to bottom, #06d7f8 0%, #f9fafa 100%);
}

.cabecera {
    text-align: center;
}

.cabecera #identificacion {
    margin-top: 10px;
    font-size: 30px;
}

.cabecera #titulo {
    margin-top: 10px;
    font-size: 20px;
}

#logo {
    width: 100px;
}

#cajaalerta {
    box-shadow: 5px 5px 5px gray;
}

.oculta {
    visibility: hidden;
}

#loginformu {
    width: 80%;
    margin: 0px auto;
    padding: 15px;
    border: 2px solid black;
    border-radius: 5px;
    box-shadow: 0px 0px 5px black;
}

#botones {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
}
#botones button {
    width: 100px;
}

#botones .aceptar {
    margin-right: 5%;
}

#footLogin {
    height: calc(100vh - 209px - 74px - 354px - 20px);
    text-align: center;
    visibility: hidden;
}