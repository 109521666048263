.icono {
    font-size: 20px;
    color: black;
    cursor: pointer;
    transition: all 300ms;
}

.iconp {
    margin-right: 0px;
}

.icono:hover {
    text-shadow: 0px 0px 5px rgb(0, 128, 255);
}

.modal-body {
    line-height: normal;
}

#mensajeCambiaClave {
    height: 60px;
    line-height: 59px;
    color: rgb(123, 2, 2);
}