#cabecera {
    height: 40px;
    line-height: 40px;
    background-color: rgb(250, 179, 94);
    border-bottom: 2px solid black;
    box-shadow: 0px 4px 4px gray;
}

#cabecera h4 {
    /* margin-top: 0px; */
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    color: black;
}

#cabecera nav {
    text-align: right;
}
#cabecera nav > img {
    margin-left: 15px;
    width: 20px;
    cursor: pointer;
}

#cajaalerta {
    box-shadow: 5px 5px 5px gray;
}

.oculta {
    visibility: hidden;
}

#cuerpo {
    width: 80%;
    height: 500px;
    overflow: hidden;
    margin: 0px auto;
    margin-top: 10px;
}

#cuerpo h3 {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 20px;
}

#cuerpo #botones {
    margin-top: 30px;
}

#footApunte {
    height: calc(100vh - 40px - 74px - 500px - 15px);
    text-align: center;
}

#footApunte p {
    padding-top: 20px;
    visibility: hidden;
}

.icouser {
    font-size: 30px;
    color: red;
}